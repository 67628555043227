.mat-mdc-form-field.mat-form-field-custom {
  display: flex;
  padding-bottom: 20px;

  .mat-mdc-text-field-wrapper {
    height: 48px;
    padding: 0;
    background-color: $transparent;
    overflow: visible;

    &.mdc-text-field--filled {

      .mdc-floating-label--float-above {
        transform: translateY(-90%) scale(0.75);
      }
    }

    .mat-mdc-form-field-flex {
      padding: 0 14px;
      border: 1px solid $disabled;
      border-radius: 8px;

      .mat-mdc-form-field-infix {
        height: 46px;
        min-height: 46px;
        padding-top: 22px;
        padding-bottom: 0;

        .mat-mdc-floating-label {
          top: 25px;
          font-family: $font-family-primary;
          font-size: 16px;
          line-height: 18px;
          letter-spacing: normal;
          color: $grey-text;
        }

        // standard input
        input,
        textarea {
          font-family: $font-family-primary;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: normal;
          color: $black-coral;
          caret-color: $black-coral;
        }

        input {

          // THIS WAS VERY DIFFICULT TO CATCH
          &::-webkit-calendar-picker-indicator {
            display: block;
          }
        }

        textarea {
          font-size: 16px;
        }

        .button-icon-search {
          top: -1px;
          z-index: 20;
        }

        // select
        .mat-mdc-select {
          line-height: 18px;

          .mat-mdc-select-value {

            .mat-mdc-select-value-text {
              font-family: $font-family-primary;
              color: $black-coral;
            }
          }

          .mat-mdc-select-arrow-wrapper {

            .mat-mdc-select-arrow {
              color: $blue;
              border-left: 7px solid transparent;
              border-right: 7px solid transparent;
              border-top: 8px solid;

              svg {
                display: none;
              }
            }
          }
        }
      }
    }

    .mdc-line-ripple {

      &::before,
      &::after {
        display: none;
        // border-bottom: none;
      }
    }
  }

  .mat-mdc-form-field-subscript-wrapper {

    &::before {
      display: none;
    }

    .mat-mdc-form-field-error-wrapper {
      position: static;
      padding: 0;

      .mat-mdc-form-field-error {
        color: $text-color-standard;
        line-height: 18px;

        &::before {
          display: none;
        }

        .error-message {
          display: inline-block;
          margin-top: 8px;
          position: relative;
          padding-left: 22px;
          font-size: 12px;
          font-family: $font-family-primary;
          line-height: 18px;
          letter-spacing: normal;

          &::before {
            display: inline-block;
            content: '!';
            width: 16px;
            height: 16px;
            position: absolute;
            top: 0;
            left: 0;
            font-family: $font-family-primary;
            font-size: 13px;
            line-height: 16px;
            color: $urgent;
            border: 1px solid $urgent;
            border-radius: 50%;
            text-align: center;
          }
        }
      }
    }

    // hint is used for custom error on edit user roles
    .mat-mdc-form-field-hint-wrapper {
      position: static;
      padding: 0;

      // custom error
      // used in edit user roles
      .custom-mat-error {
        display: inline-block;
        margin-top: 8px;
        position: relative;
        padding-left: 22px;
        font-size: 12px;
        font-family: $font-family-primary;
        line-height: 18px;
        letter-spacing: normal;
        color: $text-color-standard;

        &::before {
          display: inline-block;
          content: '!';
          width: 16px;
          height: 16px;
          position: absolute;
          top: 0;
          left: 0;
          font-family: $font-family-primary;
          font-size: 13px;
          line-height: 16px;
          color: $urgent;
          border: 1px solid $urgent;
          border-radius: 50%;
          text-align: center;
        }
      }
    }
  }

  &.with-search-icon {

    .mat-mdc-form-field-flex {

      .mat-mdc-form-field-infix {
        padding-right: 50px;
      }
    }
  }

  &.mat-form-field-disabled {
    cursor: not-allowed;

    .mat-mdc-form-field-flex {
      background-color: $lightest-gray;
      cursor: not-allowed;

      .mat-mdc-form-field-infix {

        input {
          cursor: not-allowed;
        }
      }
    }
  }

  &.mat-form-field-invalid {

    .mdc-text-field--invalid {

      &:not(.mdc-text-field--disabled):hover {

        .mdc-floating-label {
          color: $grey-text;
        }
      }

      .mat-mdc-form-field-flex {
        border-color: $urgent;
      }
    }
  }
}

// select dropdown
div.mat-mdc-select-panel.mdc-menu-surface {
  background-color: $white;
  border-radius: 8px !important;

  .mat-mdc-option {

    &:not(.mat-mdc-option-multiple) {
      min-height: 36px;
      transition: background-color .3s ease;

      &:hover {
        background-color: $anti-flash-white;
      }

      &.mdc-list-item--selected {
        background-color: $disabled;
      }

      .mdc-list-item__primary-text {
        font-family: $font-family-primary;
        font-size: 14px;
        font-weight: 700;
        color: $text-color-standard;
      }

      .mat-pseudo-checkbox {
        display: none;
      }
    }

    &.mat-mdc-option-multiple {
      height: 36px;
      min-height: 36px;
      padding: 4px 20px;
      line-height: 32px;

      &:first-of-type {
        margin-top: 13px;
      }

      &:last-of-type {
        margin-bottom: 13px;
      }

      &:hover {

        .mat-pseudo-checkbox {
          border-color: $blue;
        }
      }

      &.mat-mdc-option-disabled {
        cursor: not-allowed;

        &.mat-selected {

          &:hover {

            .mat-pseudo-checkbox {
              border-color: $blue;
            }
          }
        }

        &:hover {

          .mat-pseudo-checkbox {
            border-color: $gray;
          }
        }

        .mdc-list-item__primary-text {
          color: $gray;
        }
      }

      .mat-pseudo-checkbox {
        width: 22px;
        height: 22px;
        margin-right: 15px;
        background-color: $transparent;
        border: 2px solid $gray;
        border-radius: 4px;
        transition: border-color .3s ease, background-color .3s ease, box-shadow .3s ease;

        &::after {
          top: -1px;
          left: 2px;
          color: $white;
        }

        &.mat-pseudo-checkbox-checked {
          background-color: $blue;
          border-color: $blue;
          box-shadow: 0 0 10px #0003, 0 0 7px #4a74ff4d;
        }
      }

      .mdc-list-item__primary-text {
        font-size: 14px;
        font-weight: 700;
        color: $text-color-standard;
      }
    }
  }
}

// autocomplete
div.mat-mdc-autocomplete-panel.mdc-menu-surface {
  background-color: $white;
  border-radius: 8px !important;

  .mat-mdc-option {
    min-height: 36px;
    transition: background-color .3s ease;

    &:hover {
      background-color: $anti-flash-white;
    }

    &.mdc-list-item--selected {
      background-color: $disabled;
    }

    .mdc-list-item__primary-text {
      font-family: $font-family-primary;
      font-size: 14px;
      font-weight: 700;
      color: $text-color-standard;
    }

    .mat-pseudo-checkbox {
      display: none;
    }
  }
}

// checkbox
.mat-mdc-checkbox.mat-checkbox-custom {
  font-family: $font-family-primary;

  &.mat-mdc-checkbox-checked:not(.mat-mdc-checkbox-disabled) {

    .mdc-form-field {

      .mdc-checkbox {

        .mdc-checkbox__background {
          background-color: $blue;
          border-color: $blue;
        }
      }
    }
  }

  &.mat-mdc-checkbox-disabled {
    cursor: not-allowed;
    pointer-events: all;

    .mdc-checkbox {
      cursor: not-allowed;
    }

    .mdc-label {
      cursor: not-allowed;
    }
  }

  .mdc-checkbox {
    width: 22px;
    height: 22px;
    padding: 0;

    &:hover {

      .mdc-checkbox__native-control {

        &:not(:checked) {

          & ~ .mdc-checkbox__background {
            border-color: $gray;
          }
        }
      }
    }

    .mat-mdc-checkbox-touch-target {
      width: 22px;
      height: 22px;
    }

    .mdc-checkbox__native-control {
      width: 22px;
      height: 22px;

      &:focus {

        &:checked {

          & ~ .mdc-checkbox__background {
            border-color: $blue;
          }
        }

        & ~ .mdc-checkbox__background {
          border-color: $gray;
        }
      }

      &:focus-visible {

        & ~ .mdc-checkbox__background {
          border-color: $blue;
        }
      }
    }

    .mdc-checkbox__ripple {
      display: none;
    }

    .mdc-checkbox__background {
      width: 22px;
      height: 22px;
      position: static;
      border-color: $gray;
      border-radius: 4px;

      .mdc-checkbox__checkmark {
        width: 14px;
        top: 4px;
        right: 2px;
        bottom: 2px;
        left: 4px;

        .mdc-checkbox__checkmark-path {
          stroke: $white !important;
        }
      }
    }
  }

  .mdc-label {
    padding-left: 8px;
    margin-right: 0;
    font-family: $font-family-primary;
    font-size: 16px;
    letter-spacing: normal;
    line-height: 24px;
    color: $grey-text;
    user-select: none;
  }
}

// button
button.mat-mdc-icon-button.mdc-icon-button {
  width: 40px;
  height: 40px;
  padding: 8px;

  &:focus-visible {
    outline: 2px solid $blue;
  }
}

// menu
.mat-mdc-menu-panel {

  .mat-mdc-menu-content {

    .mat-mdc-menu-item {

      .mat-mdc-menu-item-text {
        font-family: $font-family-primary;
        font-size: 14px;
        letter-spacing: normal;
      }
    }
  }
}

// snackbar
.mdc-snackbar.mat-mdc-snack-bar-container {

  .mdc-snackbar__surface {
    background-color: $white;
  }

  .mat-mdc-snack-bar-label {
    font-family: $font-family-primary;
  }

  .mat-mdc-button {
    font-family: $font-family-primary;
  }
}

/* Fix wrong caret color in inputs */
.mat-form-field.mat-accent .mat-input-element {
  caret-color: $black;
}

/* Custom snackbar color based on panelClass property of the snackbar */
.blue-info-snackbar {
  background-color: $primary;
  color: $white;

  .mat-simple-snack-bar-content {
    &::before {
      font-family: 'Material Icons';
      content: "info";
      font-size: 24px;
      vertical-align: bottom;
      margin-right: 10px;
      line-height: 21px;
    }
  }
}